import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { environment } from '@env/environment';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ClearStream } from '@shared/decorators/clear-stream.decorator';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { InviteData } from '@shared/models/account.model';
import { UserInfo } from '@shared/models/auth.model';
import { shareRef } from '@shared/operators/share-ref.operator';
import { AuditLogService } from '@shared/services/audit-log.service';
import { AuthManager } from '@shared/services/auth-manager.service';
import {
  AuthenticationError,
  CancelAuthSignup,
  CancelEmailSignup,
  GetInvite,
  GoToLogoutUrl,
  GoToSignIn,
  GoToSignUp,
  InitAuthentication,
  LinkPassword,
  LinkProvider,
  MonitorLoginExpiration,
  PasswordUpdated,
  RemoveUserOnAuthTokenChange,
  ResetUserInfo,
  SaveClaimsOnAuthTokenChange,
  SaveTokenOnAuthTokenChange,
  SendPasswordResetEmail,
  SendVerificationEmail,
  SignOutWithoutRedirect,
  SignOutWithRedirect,
  SignUpAuthenticated,
  UnlinkPassword,
  UnlinkProvider,
  UpdateUserOnAuthTokenChange,
} from '@shared/states/auth.actions';
import { RouterState } from '@shared/states/router.state';
import { pickBy } from '@shared/utilities/object.utilities';
import { queryParamsString } from '@shared/utilities/string.utilities';
import { User } from 'firebase/auth';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

export interface AuthStateModel {
  locked?: boolean | null;
  invite?: InviteData | null;
  userInfo: UserInfo | null;

  authToken: string | null;
  authState: string | null;
  authError?: AuthError | null;
  authClaims?: Object | null;

  loginUrl?: string | null;
  logoutUrl?: string | null;

  trialToken?: string | null;
}

@Injectable()
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    invite: null,
    locked: false,
    userInfo: null,
    authState: null,
    authToken: null,
    authClaims: null,
  },
})
export class AuthState {
  @Selector()
  static locked(state: AuthStateModel): boolean {
    return state.locked;
  }

  @Selector()
  static userUid(state: AuthStateModel): string {
    return state.userInfo?.uid;
  }

  @Selector()
  static isNewUser(state: AuthStateModel): boolean {
    return Boolean(state.userInfo?.isNewUser);
  }

  @Selector()
  static isSSOLogin(state: AuthStateModel): boolean {
    const claims = state.authClaims as any;

    return (
      Boolean(claims?.firebase?.sign_in_provider?.startsWith('saml.')) ||
      Boolean(claims?.firebase?.sign_in_provider?.startsWith('oidc.'))
    );
  }

  @Selector()
  static isAnonymous(state: AuthStateModel): boolean {
    return Boolean(state.userInfo?.isAnonymous);
  }

  @Selector()
  static isAccountOk(state: AuthStateModel): boolean {
    const isAnonymous = Boolean(state.userInfo?.isAnonymous);
    const isDataLoaded = !!state.userInfo;

    return isAnonymous || isDataLoaded;
  }

  @Selector()
  static isUserVerified(state: AuthStateModel): boolean {
    const claims = state.authClaims as any;

    // We allow email missing for SSO authentications for now so it can be masked from Firebase auth logging

    console.log(
      'Verfied user',
      Boolean(
        (state.userInfo?.email && state.userInfo?.emailVerified) ||
          claims?.firebase?.sign_in_provider.startsWith('oidc.'),
      ),
    );

    return Boolean(
      (state.userInfo?.email && state.userInfo?.emailVerified) ||
        claims?.firebase?.sign_in_provider.startsWith('oidc.'),
    );
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return state.userInfo !== null;
  }

  @Selector()
  static authToken(state: AuthStateModel): string {
    return state.authToken || '';
  }

  @Selector()
  static authClaims(state: AuthStateModel): any {
    return state.authClaims || {};
  }

  @Selector()
  static authScope(state: AuthStateModel): string {
    return state.authClaims ? (state.authClaims as any).scope : '';
  }

  @Selector()
  static authError(state: AuthStateModel): string {
    return state.authError;
  }

  @Selector()
  static invite(state: AuthStateModel): InviteData {
    return state.invite;
  }

  @Selector()
  static isInvite(state: AuthStateModel): boolean {
    return Boolean(state.invite?.$key);
  }

  @Selector()
  static info(state: AuthStateModel): UserInfo {
    return state.userInfo;
  }

  @Selector()
  static isGoogleConnected(state: AuthStateModel): boolean {
    const hasProvider = state?.userInfo?.providerData?.some((data) => data.providerId === 'google.com');
    return hasProvider || false;
  }

  @Selector()
  static isMicrosoftConnected(state: AuthStateModel): boolean {
    const hasProvider = state?.userInfo?.providerData?.some((data) => data.providerId === 'microsoft.com');
    return hasProvider || false;
  }

  @Selector()
  static allowPublicComments(state: AuthStateModel): boolean {
    const authClaims = state.authClaims as any;

    return Boolean(
      authClaims?.ssoTenant === 'zef' &&
        authClaims?.firebase?.sign_in_provider === 'oidc.zef' &&
        (authClaims?.firebase?.sign_in_attributes?.reportPublicComments ||
          authClaims?.firebase?.sign_in_attributes?.groups?.includes('Report admins')),
    );
  }

  @Selector()
  static userInfoForSSOProvider(state: AuthStateModel): Partial<UserInfo | null> {
    const userInfo = state?.userInfo?.providerData?.find((data) => data.providerId === 'oidc.sso');

    return userInfo || null;
  }

  @Selector()
  static userInfoForGoogleProvider(state: AuthStateModel): Partial<UserInfo | null> {
    const userInfo = state?.userInfo?.providerData?.find((data) => data.providerId === 'google.com');

    return userInfo || null;
  }

  @Selector()
  static userInfoForMicrosoftProvider(state: AuthStateModel): Partial<UserInfo | null> {
    const userInfo = state?.userInfo?.providerData?.find((data) => data.providerId === 'microsoft.com');

    return userInfo || null;
  }

  @Selector()
  static userInfoForPasswordProvider(state: AuthStateModel): Partial<UserInfo | null> {
    const userInfo = state?.userInfo?.providerData?.find((data) => data.providerId === 'password');

    return userInfo || null;
  }

  @Selector()
  static isEmailConnected(state: AuthStateModel): boolean {
    const hasEmail = state?.userInfo?.providerData?.some((data) => data.providerId === 'password');

    return hasEmail || false;
  }

  @Selector()
  static loginUrl(state: AuthStateModel): string {
    return state.loginUrl || '';
  }

  @Selector()
  static isZefAdmin(state: AuthStateModel) {
    return state.userInfo?.email?.endsWith('@zef.fi') || false;
  }

  @Selector()
  static isZefDeveloper(state: AuthStateModel) {
    return [
      'aleksandar.milosevic@zef.fi',
      'elena.kruijt@zef.fi',
      'janne.julkunen@zef.fi',
      'juha.koskela@zef.fi',
      'markku.alasaarela@zef.fi',
      'poul.kruijt@zef.fi',
      'saad.chaudhry@zef.fi',
      'joonas.vuolukka@zef.fi',
      'antti.suomi@zef.fi',
    ].includes(state.userInfo?.email);
  }

  @Selector()
  static isInviteAccepted({ invite }: AuthStateModel) {
    return Boolean(invite?.accepted);
  }

  constructor(
    @Inject(LOCALE_ID) readonly locale: string,
    @Optional() private ls: LocalStorageService,
    @Optional() private ss: SessionStorageService,
    private store: Store,
    private am: AuthManager,
    private al: AuditLogService,
  ) {}

  @Action(InitAuthentication)
  initAuthentication(_, { disableAnonymous }: InitAuthentication) {
    return this.am.init(disableAnonymous);
  }

  @Action(UpdateUserOnAuthTokenChange)
  login({ getState, patchState }: StateContext<AuthStateModel>, { userInfo }: UpdateUserOnAuthTokenChange) {
    const state = getState();

    const signedIn = state.userInfo ? state.userInfo.uid !== userInfo.uid : false;
    const userChanged = state.userInfo ? state.userInfo.uid !== userInfo.uid : true;
    const isReportSignin = userInfo?.email?.includes('+report') || false;

    if (signedIn && !isReportSignin) {
      console.log('%cExternal sign in detected', 'color: red;', state.userInfo, userInfo);

      return this.al.active$.pipe(
        filter((active) => !active),
        tap(() => window.location.reload()),
      );
    } else if (userChanged) {
      console.debug('Active user info', JSON.stringify(userInfo));

      console.log('Active user changed', state.userInfo?.uid, userInfo.uid);
    }

    patchState({ userInfo });
  }

  @Action(RemoveUserOnAuthTokenChange)
  logout({ getState, setState, patchState }: StateContext<AuthStateModel>) {
    const state = getState();

    const signedOut = !!state.userInfo;

    const trialToken = state.authToken || null;

    const wasAnonymous = state.userInfo && state.userInfo.isAnonymous;

    if (this.ls) {
      this.ls.clear('report-team');
    }

    // default AuthStateModel
    setState({
      invite: null,
      userInfo: null,
      authState: null,
      authToken: null,
      authClaims: null,
    });

    if (signedOut) {
      console.log('%cExternal sign out detected', 'color: red;');

      // dispatch(new Navigate(['/login']));
      // window.location.reload();
    } else if (trialToken && wasAnonymous) {
      patchState({ trialToken });
    }
  }

  @Action(SaveTokenOnAuthTokenChange)
  updateToken({ getState, patchState }: StateContext<AuthStateModel>, { authToken }: SaveTokenOnAuthTokenChange) {
    const state = getState();

    const tokenChanged = state.authToken ? state.authToken !== authToken : true;

    if (tokenChanged) {
      console.log('Auth token updated', state.userInfo);
    }

    patchState({ authToken });
  }

  @StreamAction(SaveClaimsOnAuthTokenChange)
  updateClaims({ patchState }: StateContext<AuthStateModel>, { authClaims }: SaveClaimsOnAuthTokenChange) {
    patchState({ authClaims });

    console.log('Custom token claims', authClaims);
    console.debug('Custom token claims', JSON.stringify(authClaims));

    return !authClaims?.firebase?.sign_in_provider?.startsWith('saml.') &&
      !authClaims?.firebase?.sign_in_provider?.startsWith('oidc.')
      ? of(null)
      : this.am.getSSOSettings(authClaims?.ssoTenant || authClaims.firebase.sign_in_provider.slice(5)).pipe(
          map((loginSettings: any) => {
            console.log('SSO login settings', loginSettings);

            return patchState({ logoutUrl: loginSettings?.logoutUrl || 'https:' + environment.publicUrl });
          }),
          shareRef(),
        );
  }

  @StreamAction(MonitorLoginExpiration)
  monitorLoginExpiration({ dispatch }: StateContext<AuthStateModel>, { userUid }: MonitorLoginExpiration) {
    return this.am.loginExpires(userUid).pipe(
      map((loginExpiration: any) => {
        console.log(
          'SSO login expiration',
          loginExpiration?.payload?.exists() ? new Date(loginExpiration.payload.val()).toUTCString() : null,
        );

        return !loginExpiration?.payload?.exists() ? dispatch(new SignOutWithRedirect(true)) : of(false);
      }),
      shareRef(),
    );
  }

  @Action(SendVerificationEmail)
  SendVerificationEmail({ getState }: StateContext<AuthStateModel>, { redirect }: SendVerificationEmail) {
    const state = getState();
    const token = state.authToken;

    return this.am.sendInvite(token, true, redirect);
  }

  @StreamAction(GetInvite)
  getInvite({ patchState }: StateContext<AuthStateModel>, { inviteKey }: GetInvite) {
    return !inviteKey
      ? of(null)
      : this.am.getInvite(inviteKey).pipe(tap((invite: InviteData) => patchState({ invite })));
  }

  @Action(ResetUserInfo)
  resetUserInfo({ patchState }: StateContext<AuthStateModel>) {
    patchState({ userInfo: null });
  }

  @Action(SignUpAuthenticated)
  @ClearStream()
  signUpAuthenticated({ dispatch }: StateContext<AuthStateModel>) {
    // TODO: We might want to set some error state here like no account found, you want to sign up?

    console.log('Authenticated user sign-up');

    dispatch(new Navigate(['/signup']));
  }

  @Action(SignOutWithRedirect)
  signOut({ dispatch, getState, patchState }: StateContext<AuthStateModel>, { signIn }: SignOutWithRedirect) {
    console.log('User initiated sign-out', signIn);

    this.ls.clear('signup');

    const state = getState();

    patchState({ locked: true });

    const authClaims = state.authClaims as any;

    const loginError = sessionStorage.getItem('login-error');

    const isSSOLogin =
      !!authClaims?.firebase?.sign_in_provider?.startsWith('saml.') ||
      !!authClaims?.firebase?.sign_in_provider?.startsWith('oidc.');

    return this.am.signOut().pipe(
      switchMap(() => {
        if (isSSOLogin) {
          console.log('SSO logout initiated', state.logoutUrl);

          if (loginError) {
            return dispatch(new GoToSignIn());
          } else if (state.logoutUrl) {
            return dispatch(new GoToLogoutUrl(state.logoutUrl));
          } else {
            return signIn ? dispatch(new GoToSignIn()) : of(null);
          }
        } else {
          return signIn || loginError ? dispatch(new GoToSignIn()) : of(null);
        }
      }),
    );
  }

  @Action(SignOutWithoutRedirect)
  @ClearStream()
  signOutOnly() {
    console.log('Signout without redirect');

    return this.am.signOut();
  }

  @Action(CancelEmailSignup)
  cancelEmailSignup() {
    return this.am.unlinkPassword().pipe(catchError(() => of(null)));
  }

  @Action(CancelAuthSignup)
  cancelGoogleSignup({ dispatch }: StateContext<AuthStateModel>) {
    return this.am.deleteUser().pipe(tap(() => dispatch(new SignOutWithoutRedirect())));
  }

  @Action(UnlinkPassword)
  unlinkPassword() {
    return this.am.unlinkPassword();
  }

  @Action(LinkPassword)
  linkPassword({ getState, dispatch }: StateContext<AuthStateModel>, { newPassword, oldPassword }: LinkPassword) {
    const state = getState();
    const email = state.userInfo?.email;

    if (email) {
      if (!oldPassword) {
        return this.am.reAuthenticateWithGoogle().pipe(
          switchMap((ok) => (!ok ? of(null) : this.am.linkPassword(email, newPassword))),
          map((ok) => (!ok ? null : dispatch(new PasswordUpdated()))),
          catchError(() => of(null)),
        );
      } else {
        return this.am.reAuthenticateWithEmailPassword(email, oldPassword).pipe(
          catchError(() => of(null)),
          switchMap((user: User) => (!user ? of(null) : this.am.updatePassword(newPassword))),
          map((ok) => (!ok ? null : dispatch(new PasswordUpdated()))),
        );
      }
    }
  }

  @Action(LinkProvider)
  linkProvider(ctx: StateContext<AuthStateModel>, { provider }: LinkProvider) {
    console.warn('Linking provider', provider);

    const $ =
      provider === 'google.com'
        ? this.am.linkGoogle()
        : provider === 'microsoft.com'
          ? this.am.linkMicrosoft()
          : of(null);

    return $.pipe(
      tap(console.warn),
      catchError(() => of(null)),
    );
  }

  @Action(UnlinkProvider)
  unlinkProvider(ctx: StateContext<AuthStateModel>, { provider }: UnlinkProvider) {
    return this.am.unlinkProvider(provider);
  }

  @Action(GoToSignIn)
  goToSignIn({ dispatch, patchState }: StateContext<AuthStateModel>, { mode, redirect, activate, lang }: GoToSignIn) {
    const redirectParams = pickBy({ activate, redirect, mode, lang }, (value) => !!value);
    const url = this.store.selectSnapshot(RouterState.url);

    console.log('Starting sign-in process', redirectParams, url);

    patchState({ locked: false });

    if (url !== '/login') {
      dispatch(new Navigate(['/login'], redirectParams));
    }
  }

  @Action(GoToSignUp)
  goToSignUp({ dispatch }: StateContext<AuthStateModel>, { redirect, activate }: GoToSignUp) {
    const signup = this.store.selectSnapshot(RouterState.routeParams).signup;

    const redirectParams = pickBy({ activate: activate || signup, redirect, signup: true }, (value) => !!value);

    console.log('Starting sign-up process', redirectParams);

    if (activate) {
      const locale = this.locale.split('-')[0] || 'en';

      const url = locale !== 'fi' ? '/surveys/#/surveys' : '/fi/kyselyt/#/surveys';

      window.location.assign(`https:${environment.wwwAddress}${url}` + queryParamsString(redirectParams));
    } else {
      dispatch(new Navigate(['/surveys'], redirectParams));
    }
  }

  @Action(GoToLogoutUrl)
  goToLogoutUrl({ getState }, { logoutUrl }: GoToLogoutUrl) {
    const state = getState();

    console.log('Logout url', logoutUrl, state.logoutUrl);

    setTimeout(() => window.location.assign(logoutUrl), 0);
  }

  @Action(AuthenticationError)
  authenticationError({ patchState }: StateContext<AuthStateModel>, { code }: AuthenticationError) {
    patchState({ authError: code || undefined });
  }

  @Action(SendPasswordResetEmail)
  sendPasswordResetEmail(_, { email }: SendPasswordResetEmail) {
    return this.am.sendEmailPasswordResetEmail(email);
  }
}

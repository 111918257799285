export enum HelpSubject {
  OrgSettingsProperties = 'org-settings-properties',
  OrgSettingsPeople = 'org-settings-people',
  OrgSettingsMember = 'org-settings-member',
  OrgSettingsAdmin = 'org-settings-admin',
  OrgSettingsOwner = 'org-settings-owner',
  OrgSettingsLeave = 'org-settings-leave',
  OrgSettingsRemoveMember = 'org-settings-remove-member',
  OrgSettingsEmailInvite = 'org-settings-email-invite',
  OrgSettingsEmailConfiguration = 'org-settings-email-configuration',
  OrgIntegrationApiKey = 'org-integration-api-key',
  OrgBetaFeatures = 'org-beta-features',
  OrgBetaEnableAll = 'org-beta-enable-all',
  OrgBetaAiInterview = 'org-beta-ai-interview',
  OrgBetaSurveyMakerAi = 'org-beta-survey-maker-ai',
  OrgBetaIntegrations = 'org-beta-integrations',
  OrgBetaInsights = 'org-beta-insights',

  QuestionCollectContact = 'question-collect-contact',

  QuestionInfoText = 'question-info-text',
  QuestionFreeText = 'question-free-text',
  QuestionSliderVolume = 'question-slider-volume',
  QuestionSliderRange = 'question-slider-range',
  QuestionSlider1D = 'question-slider-1d',
  QuestionSlider2D = 'question-slider-2d',
  QuestionSliderNPS = 'question-slider-nps',
  QuestionSliderENPS = 'question-slider-e-nps',
  QuestionChoiceText = 'question-choice-text',
  QuestionChoiceTextMulti = 'question-choice-text-multi',
  QuestionChoicePicture = 'question-choice-picture',
  QuestionInputUrl = 'question-input-url',
  QuestionInputEmail = 'question-input-email',
  QuestionInputPhone = 'question-input-phone',
  QuestionInputNumber = 'question-input-number',
  QuestionInputString = 'question-input-string',
  QuestionInputAddress = 'question-input-address',
  QuestionInputCheckbox = 'question-input-checkbox',
  QuestionInputDropdown = 'question-input-dropdown',
  QuestionInputNumeric = 'question-input-numeric',
  QuestionFileUpload = 'question-file-upload',
  QuestionUploadFormats = 'question-upload-formats',
  QuestionGroupCards = 'question-group-cards',
  QuestionGroupScored = 'question-group-scored',
  QuestionAIInterviewer = 'question-ai-interviewer',
  QuestionEskoWhyFinder = 'question-esko-why-finder',
  QuestionEskoContext = 'question-esko-context',
  QuestionMandatory = 'question-mandatory',
  QuestionArchive = 'question-archive',
  QuestionRestore = 'question-restore',

  OutcomeArchive = 'outcome-archive',
  OutcomeRestore = 'outcome-restore',

  ResultThankYou = 'result-thank-you',
  ResultOutcome = 'result-outcome',

  ResultRankingShowOutcomes = 'result-ranking-show-outcomes',
  ResultRankingShowScore = 'result-ranking-show-score',

  SurveyPublish = 'survey-publish',
  SurveyWelcome = 'survey-welcome',
  SurveyPrivacyPolicy = 'survey-privacy-policy',

  SurveyQuestions = 'survey-questions',
  SurveyResults = 'survey-results',
  SurveyNotifications = 'survey-notifications',
  SurveyNotificationsPersonal = 'survey-notifications-personal',
  SurveyNotificationsRecipients = 'survey-notifications-recipients',

  SurveyDesignStyleUrl = 'survey-design-style-url',
  SurveyDesignFont = 'survey-design-font',
  SurveyDesignBot = 'survey-design-bot',

  SurveySettingsLanguages = 'survey-settings-languages',
  SurveySettingsSharing = 'survey-settings-sharing',
  SurveySettingsOutcomeSharing = 'survey-settings-outcome-sharing',
  SurveySettingsAutoJump = 'survey-settings-auto-jump',
  SurveySettingsAccessibilityBanner = 'survey-settings-accessibility-banner',
  SurveySettingsCollaborators = 'survey-settings-collaborators',
  SurveySettingsOwner = 'survey-settings-owner',
  SurveySettingsView = 'survey-settings-view',
  SurveySettingsEdit = 'survey-settings-edit',

  SurveyLogicStudio = 'survey-logic-studio',

  SurveyAnonymous = 'survey-anonymous',
  SurveyNonAnonymous = 'survey-non-anonymous',

  SurveyShares = 'survey-shares',
  SurveyShareDefaultLink = 'survey-share-default-link',
  SurveyShareHashtags = 'survey-share-hashtags',
  SurveyShareLink = 'survey-share-link',
  SurveyShareEmail = 'survey-share-email',
  SurveyShareEmbed = 'survey-share-embed',
  SurveySharePopup = 'survey-share-popup',
  SurveySharePoll = 'survey-share-poll',
  SurveyShareEmailPreviewText = 'survey-share-email-preview-text',
  SurveyShareTwoStepInvite = 'survey-share-two-step-invite',
  SurveyShareInvites = 'survey-share-invites',
  SurveyShareEmailAnonymous = 'survey-share-email-anonymous',
  SurveyShareEmailRecipients = 'survey-share-email-recipients',
  SurveyShareEmailStatus = 'survey-share-email-status',
  SurveyShareSmsApproxCount = 'survey-share-sms-approx-count',

  PublishQuestionLength = 'publish-question-length',
  PublishQuestionFirst = 'publish-question-first',
  PublishSurveyDesign = 'publish-survey-design',
  PublishSurveyOutcomes = 'publish-survey-outcomes',
  PublishSurveySharing = 'publish-survey-sharing',
  PublishSurveyVersion = 'publish-survey-version',

  AnalyzeInstructions = 'analyze-instructions',
  AnalyzeSaveReport = 'analyze-save-report',
  AnalyzeReportConsensus = 'analyze-report-consensus',
  AnalyzeCompareRespondents = 'analyze-compare-respondents',
  AnalyzeReportShareLive = 'analyze-report-share-live',
  AnalyzeReportSharePublicReport = 'analyze-report-share-public-report',
  AnalyzeReportShareSafeReport = 'analyze-report-share-safe-report',
  AnalyzeReportShareFreezeTexts = 'analyze-report-share-freeze-texts',
  AnalyzeReportShareExtraRespondentFieldAnonymity = 'analyze-report-share-extra-respondent-field-anonymity',
  AnalyzeIntelligentZoom = 'analyze-intelligent-zoom',

  ContactsEditColumns = 'contacts-edit-columns',
  ContactsLists = 'contacts-lists',
  ContactsImportCopyPaste = 'contacts-import-copy-paste',
  ContactsImportUploadFile = 'contacts-import-upload-file',
  ContactsImportTextQualifier = 'contacts-import-text-qualifier',
  ContactsImportDelimiter = 'contacts-import-delimiter',
  ContactsImportDelimitersConsecutive = 'contacts-import-delimiters-consecutive',
  ContactsImportColumns = 'contacts-import-columns',
  ContactsImportPhoneRepair = 'contacts-import-phone-repair',
  ContactsImportUpdateContacts = 'contacts-import-update-contacts',
  ContactsCountPageHeader = 'contacts-count-page-header',

  EmailPreviewText = 'email-preview-text',
  EmailStatsRecipients = 'email-stats-recipients',
  EmailChooseInvitation = 'email-choose-invitation',
  InvitationDraft = 'invitation-draft',

  ProfileUserProperties = 'profile-user-properties',

  BillingPlanLimits = 'billing-plan-limits',
  BillingContacts = 'billing-contacts',
  BillingResponses = 'billing-responses',
  BillingSMS = 'billing-sms',
  BillingSMSAutoRefill = 'billing-sms-auto-refill',

  DiscoverShortSweet = 'discover-short-sweet',
  DiscoverDetailedDeep = 'discover-detailed-deep',

  IntegrationConflictResolution = 'integration-conflict-resolution',
  IntegrationListAddingContacts = 'integration-list-adding-contacts',
  IntegrationListRemovingContacts = 'integration-list-removing-contacts',
  IntegrationApiKey = 'integration-api-key',
  IntegrationServices = 'integration-services',
  IntegrationCustomServices = 'integration-custom-services',
  IntegrationLists = 'integration-lists',
  IntegrationProperties = 'integration-properties',
  IntegrationPropertiesOptions = 'integration-properties-options',
  IntegrationQuestionTypeChange = 'integration-question-type-change',
  IntegrationSurveyExportCurrentRespondents = 'integration-survey-export-current-respondents',
  IntegrationSurveyCreateNewContacts = 'integration-survey-create-new-contacts',

  StartInAccessibilityMode = 'start-in-accessibility-mode',
  SurveyLinkCustomParameters = 'survey-link-custom-parameters',
  TemplateSettings = 'template-settings',
  TemplateDisableAnonymity = 'template-disable-anonymity',
  TemplateDisableLogic = 'template-disable-logic',
  TemplateDisableInvites = 'template-disable-invites',
  TemplateDisableNotifications = 'template-disable-notification',
  TemplateDisableSetup = 'template-disable-setup',
  TemplateDisableWelcome = 'template-disable-welcome',
  TemplateDisableSocial = 'template-disable-social',
  TemplateQuestionSettings = 'template-question-settings',
  TemplateLimitQuestions = 'template-limit-questions',
  TemplateDisableNewLanguage = 'template-disable-new-language',
  ReleaseNotes = 'help-release-notes',
}

export enum HelpGuide {
  SurveyNewFromTemplate = 'guide-survey-new-from-template',
  SurveyLogicStudio = 'guide-survey-logic-studio',
  SurveyPublish = 'guide-survey-publish',
  ReleaseNotes = 'guide-release-notes',
}
